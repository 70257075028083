.hg-button {
    font-weight: bold;
}

::-webkit-scrollbar {
    width: 3px;
    height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #0E2060;
    border-radius: 5px;
}

/* Handle on hover */
.red-scroll::-webkit-scrollbar-thumb:hover {
    background: #0E2060;
}

.red-scroll::-webkit-scrollbar {
    width: 15px;
    height: 15px;
}

/* Track */
.red-scroll::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
.red-scroll::-webkit-scrollbar-thumb {
    background: #E52323;
    border-radius: 5px;
}

/* Handle on hover */
.red-scroll::-webkit-scrollbar-thumb:hover {
    background: #E52323;
}